import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import {
  Grid,
  Button,
  Container,
  Stack,
  Chip,
  InputAdornment,
  Typography,
  Box,
  IconButton
} from '@mui/material';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ShareIcon from '@mui/icons-material/Share';
import NotesIcon from '@mui/icons-material/Notes';

import moment from 'moment';
moment.locale('kr');

function MainEventCard(props){
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box sx={{width:"100%", position:'relative', paddingBottom:'160%'}}>
        <img src="https://slash5.winenliquor.com/wp-content/uploads/what-can-alcohol-content-tell-us-about-your-whisky-2.jpg"
          style={{
            position:'absolute',
            top:'0px', left:'0px',
            objectFit:'cover',
            right:'0px', bottom:'0px', width:'100%', height:'100%', borderRadius:'24px'}}/>
        <Box sx={{position:'absolute',
          top:'0px',
          left:'0px',
          right:'0px', bottom:'0px', width:'100%', height:'100%', borderRadius:'24px', background:'linear-gradient(180deg, rgba(0,0,0,255), rgba(0,0,0,0), rgba(0,0,0,255))'}}>
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{position:'absolute', top:'0px', left:'0px', width:'100%', padding:'24px'}}>
          <FavoriteBorderIcon sx={{fontSize:'16px', color:'#ffffff'}}/>
          <Typography variant="span" element="span" sx={{textAlign:"left", fontWeight:500, fontSize:'14px', color:'#ffffff'}}>
            1,234
          </Typography>
          <RemoveRedEyeIcon  sx={{marginLeft:'16px !important', fontSize:'16px', color:'#ffffff'}}/>
          <Typography variant="span" element="span" sx={{textAlign:"left", fontWeight:500, fontSize:'14px', color:'#ffffff'}}>
            1,234
          </Typography>
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
          sx={{position:'absolute',
          padding:'24px',
          left:'0px',
          right:'0px', bottom:'0px', width:'100%'}}>
          <Typography
            variant="h6"
            element="p"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              textAlign:"left",
              fontWeight:900, fontSize:'24px', color:'#ffffff', paddingLeft:'6px'}}>
            오늘의 오드비 이벤트
          </Typography>
          <Typography variant="h6" element="p" sx={{textAlign:"left", mt:'0px !important', fontWeight:500, fontSize:'16px', color:'#dddddd', paddingLeft:'6px'}}>
            {moment().fromNow()}
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}>
            <IconButton aria-label="like">
              <FavoriteBorderIcon sx={{color:'#ffffff'}}/>
            </IconButton>
            <IconButton aria-label="comment">
              <NotesIcon sx={{color:'#ffffff'}}/>
            </IconButton>
            <IconButton aria-label="share">
              <ShareIcon sx={{color:'#ffffff'}}/>
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
};

export default MainEventCard;
