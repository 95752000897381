import React, {useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  Grid,
  Box,
  Typography,
  Stack,
  Button,
  Chip,
  Container
} from '@mui/material';

import { styled } from '@mui/material/styles';

import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { pink, blue, red } from '@mui/material/colors';

import moment from 'moment';
import 'moment/locale/ko';

export default function Footer(props){

  const navigate = useNavigate();

  const handleYoutubeLink = () => {
      navigate("/forward/youtube");
  }

  const handleInstagramLink = () => {
      navigate("/forward/instagram");
  }

  return (
    <Box sx={{width:'100%', position:'relative',boxSizing:'border-box', backgroundColor:'#222222', padding:'72px 36px'}}>
      <Container sx={{width:'100%', position:'relative'}}>
        <Grid container
          spacing={2}
          sx={{width:'100%'}}>
          <Grid item
            xs={12}
            sm={12}
            md={12}>
            <Typography element="p" sx={{textAlign:"left", fontSize:'15px', color:'#aaaaaa'}}>
              <img src="/icons/eaudevie_italic_white.png"
                style={{position:'relative', width:'180px'}}/>
            </Typography>
          </Grid>
          <Grid item
            xs={12}
            sm={6}
            md={6}>
            <Typography element="p" sx={{
              fontFamily: 'Gowun Bold',
              textAlign:"left",
              fontSize:'15px',
              color:'#aaaaaa'}}>
            B1, 520-1, Bongcheon-ro, Gwanak-gu, Seoul
            </Typography>
            <Typography element="p" sx={{
              fontFamily: 'Gowun Bold',
              textAlign:"left",
              fontSize:'15px',
              color:'#aaaaaa'}}>
            20:00 - 02:00, Every day.
            </Typography>
            <Typography element="p" sx={{textAlign:"left", fontSize:'15px', color:'#aaaaaa', textDecoration:'none'}}>
            <a
              style={{
                fontFamily: 'Gowun Bold',
                textDecoration:'none',
                color:'#aaaaaa'}}
              href="tel:0507-1398-2397">0507-1398-2397</a>
            </Typography>
          </Grid>
          <Grid item
            xs={12}
            sm={6}
            md={6}>
            <Typography element="p" sx={{
              fontFamily: 'Gowun Bold',
              textAlign:"left",
              fontSize:'18px',
              mb:'12px', color:'#aaaaaa', fontWeight:'900'}}>
            Social
            </Typography>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}>
              <Chip
                onClick={handleYoutubeLink}
                sx={{
                  color:'#bbbbbb',
                  fontFamily: 'Gowun Bold',
                }}
                variant="outlined"
                icon={<YouTubeIcon />} label="Youtube" />
              <Chip
                onClick={handleInstagramLink}
                sx={{
                  color:'#bbbbbb',
                  fontFamily: 'Gowun Bold',
                }}
                variant="outlined" icon={<InstagramIcon />} label="Instagram" />
            </Stack>
          </Grid>
          <Grid item
            md={12}>
            <Typography element="p" sx={{
              fontFamily: 'Gowun Regular',
              textAlign:"left",
              fontSize:'12px ',
              mb:'4px',
              color:'#aaaaaa'}}>
              Eau de Vie ⓒ 2024. All Rights Reserved.
            </Typography>
            <Typography element="p" sx={{
              fontFamily: 'Gowun Regular',
              textAlign:"left",
              fontSize:'12px ',
              color:'#aaaaaa'}}>
              Powered by Gigto Link.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
