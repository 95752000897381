import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';

import {
  Grid,
  Button,
  Container,
  Stack,
  Chip,
  InputAdornment,
  Typography,
  Box
} from '@mui/material';

import WineBarTwoToneIcon from '@mui/icons-material/WineBarTwoTone';
import LiquorIcon from '@mui/icons-material/Liquor';
import TagIcon from '@mui/icons-material/Tag';

function MenuItem(props){

  const { key, item } = props;
  const navigate = useNavigate();

  const handleMenuItemClick = () => {
    navigate('/item/'+item.key);
  };

  return (
    <Grid
      item
      onClick={handleMenuItemClick}
      sm={6} md={3} lg={2} xs={6}>
      <Box sx={{position:'relative', width:'100%', paddingBottom:'100%', mb:'12px'}}>
        <img src="/images/whiskey2.jpg" style={{
          position:'absolute',
          top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
          cursor:'pointer',
          borderRadius:'16px', objectFit:'cover'
        }}/>
      </Box>
      <Typography element="p" sx={{
        fontFamily: 'Gowun Bold',
        cursor:'pointer',
        textAlign:"left", fontWeight:900, mb:'12px', fontSize:{xs:'16px', sm:'20px'}}}>
        {item && item.title.kr}
      </Typography>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{cursor:'pointer'}}
        spacing={0}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}>
          <WineBarTwoToneIcon
            fontSize="small"/>
          <Typography element="p" sx={{
            fontFamily: 'Gowun Bold',
            textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
            {item && item.price.shot.toLocaleString()}
          </Typography>
        </Stack>
        {item && item.price.bottle > 0 && <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{marginBottom:'12px'}}
          spacing={2}>
          <LiquorIcon
            fontSize="small"/>
          <Typography element="p" sx={{
            fontFamily: 'Gowun Bold',
            textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
            {item && item.price.bottle.toLocaleString()}
          </Typography>
        </Stack>}
        <Typography
          sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              textAlign:'left',
              lineHeight:2,
              WebkitLineClamp: 2,
          }}>
          {
            (item.tags.length > 0) &&
            item.tags.map((el) => (
              (el.length > 0) && <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label={el} />
            ))
          }
        </Typography>
      </Stack>
    </Grid>
  );
};

export default MenuItem;
