import React, {useEffect, useState, useRef} from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import WineBarTwoToneIcon from '@mui/icons-material/WineBarTwoTone';
import LiquorIcon from '@mui/icons-material/Liquor';
import TagIcon from '@mui/icons-material/Tag';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import {
  Grid,
  Button,
  Container,
  Stack,
  Chip,
  InputAdornment
} from '@mui/material';

import MenuItem from '../../components/menu/MenuItem';
import Footer from '../../components/common/Footer';

import ProductController from '../../apis/products/ProductController';

import { blue, amber, orange, deepOrange } from '@mui/material/colors';

const drawerWidth = 240;

const StyledTextField = styled(TextField)`
    fieldset {
        border-radius: 28px;
        border-color: #ffffff;
        color: #ffffff;
      },
    label {
        color:#ffffff;
    },
    input {
      color: #ffffff;
    },
    .Mui-focused fieldset {
        border-color: #ffffff;
    }
`;

const CATEGOTY_LIST = [
  {
    key:"SCOTCH_SINGLE",
    title:"스카치 싱글몰트",
    description:"스코틀랜드는 위스키의 발상지로 전 세계적으로 유명합니다. 스코틀랜드 위스키는 그 특유의 풍미와 품질로 인정받고 있으며, 크게 하이랜드(Highland), 스페이사이드(Speyside), 로우랜드(Lowland), 아일라(Islay), 캠벨타운(Campbeltown)의 5개 주요 지역으로 나뉩니다. 각 지역은 고유의 기후와 지형적 특성을 바탕으로 독특한 맛과 향을 지닌 위스키를 생산합니다.",
    url: "/"
  },{
    key:"SCOTCH_ISLAY_SINGLE",
    title:"스카치 아일라",
    description:"스코틀랜드의 아일라 섬에서 생산되는 위스키로, 독특한 스모키하고 피트 향이 특징입니다. 이 지역의 위스키는 종종 강렬한 바다의 풍미와 함께 강한 스모크와 미네랄 노트를 가지며, 복잡한 맛의 깊이를 제공합니다. 아일라 위스키는 주로 원주율이 높은 캐스크 스트렝스와 스모키한 특성을 지닌 제품들이 많아, 독특하고 강렬한 풍미를 경험할 수 있습니다.",
    url: "/"
  },{
    key:"SCOTCH_BLENDED",
    title:"스카치 블렌디드",
    description:"여러 종류의 스카치 몰트와 그레인 위스키를 혼합하여 만들어지는 위스키입니다. 이 블렌디드는 각기 다른 개별 위스키의 장점을 결합하여 균형 잡힌 맛과 향을 제공하며, 종종 부드럽고 매끄러운 특성을 지니고 있습니다. 블렌디드 스카치는 일반적으로 다양한 맛의 복합성을 가지고 있어, 달콤하고 과일 향, 부드러운 오크 노트 등이 조화롭게 어우러지며, 다양한 음용 방식에 적합한 위스키입니다.",
    url: "/"
  },{
    key:"IRISH",
    image:"/images/ireland.jpg",
    title:"아이리시",
    description:"아이리시 위스키는 아일랜드에서 생산되는 위스키로, 일반적으로 부드럽고 깔끔한 맛이 특징입니다. 대개 3중 증류 과정을 거쳐 더 매끄럽고 순한 맛을 가지며, 캐릭터는 종종 바닐라, 과일, 그리고 약간의 스파이시함이 어우러진 복합적인 풍미를 보여줍니다. 아이리시 위스키는 몰트, 그레인, 또는 둘의 혼합으로 만들어지며, 스트레이트로 마시거나 다양한 칵테일에 사용하기에 적합한 위스키입니다.",
    url: "/"
  },{
    key:"INDEPENDENT",
    title:"독립병입",
    description:"특정 증류소에서 생산된 원주를 독립적으로 병입하여 판매하는 위스키를 의미합니다. 이러한 위스키는 특정 배치나 캐스크에서 직접 병입되어, 증류소의 공식 제품보다 더욱 독특하고 개성 있는 맛과 향을 제공하는 경우가 많습니다. 독립병입자는 원주를 병입하기 전에 추가적인 숙성이나 블렌딩을 하지 않고, 원주가 가진 고유의 특성과 개성을 그대로 유지합니다. 이로 인해, 독특한 캐스크의 특성과 깊이 있는 맛을 경험할 수 있어 위스키 애호가들에게 큰 인기를 끌고 있습니다.",
    url: "/"
  },{
    key:"DIAGEO",
    title:"디아지로 SR",
    description:"DIAGEO는 세계적인 주류 기업으로, 다양한 스카치 위스키 브랜드를 소유하고 있는 유명한 회사입니다. DIAGEO의 위스키 포트폴리오는 대표적인 스카치 몰트 및 블렌디드 위스키를 포함하며, 각각 독특한 맛과 풍미를 제공합니다. 이 회사는 전통적인 제조 방식과 현대적인 기술을 결합하여 품질 높은 위스키를 생산하며, 브랜드의 역사와 전통을 존중하는 동시에 혁신을 추구합니다.",
    url: "/"
  },{
    key:"BOURBON",
    image:"/images/america.jpg",
    title:"아메리칸 위스키(버번,라이)",
    description:"아메리칸 위스키는 미국에서 생산되는 다양한 스타일의 위스키를 포함하며, 주로 버번, 라이, 테네시 위스키 등이 있습니다. 버번 위스키는 주로 옥수수로 만들어지고, 오크통에서 숙성되어 달콤하고 바닐라, 카라멜의 풍미가 특징입니다. 라이 위스키는 호밀을 주원료로 하여, 더 매콤하고 스파이시한 맛을 지니고 있습니다. 테네시 위스키는 버번과 유사하지만, 특유의 필터링 과정인 [링컨 카운티 프로세스]를 통해 부드럽고 깨끗한 맛을 제공합니다.",
    url: "/"
  },{
    key:"COGNAC",
    title:"브랜디(꼬냑, 깔바도스 등)",
    description:"과일, 주로 포도를 발효시켜 만든 주류로, 증류 과정을 통해 알코올 농도를 높이고 깊은 풍미를 더합니다. 이 음료는 다양한 과일에서 제조될 수 있지만, 포도를 사용하는 것이 가장 일반적입니다. 브랜디는 오크통에서 숙성되면서 부드럽고 복합적인 맛을 얻으며, 향긋한 과일 노트와 함께 바닐라, 캐러멜, 스파이스 같은 풍미가 조화를 이룹니다.",
    url: "/"
  },{
    key:"ETC",
    image:"/images/whiskey2.jpg",
    title:"기타지역(대만 인도 등)",
    description:"대만과 인도의 위스키는 각 나라의 독특한 제조 방식과 풍미를 자랑합니다. 대만의 위스키는 주로 카발란(Kavalan) 브랜드로 유명하며, 열대 기후에서 빠른 숙성과 깊이 있는 풍미가 특징입니다. 인도 위스키는 독특한 열대 원료와 숙성 환경으로 인해 강렬하고 스파이시한 맛이 특징이며, 오크와 스파이스, 카라멜 노트가 어우러지는 경우가 많습니다. 두 나라 모두 각자의 전통과 환경에 맞춘 위스키를 생산하여, 다양한 맛과 향을 제공합니다.",
    url: "/"
  },{
    key:"LIQUEUR",
    title:"리큐르",
    description:"증류주에 설탕, 과일 주스, 허브, 향신료 등을 추가하여 맛과 달콤함을 더한 주류입니다. 일반적으로 알코올 농도는 15%에서 30% 사이이며, 다양한 맛과 향을 제공합니다. 리큐어는 전통적으로 디저트, 칵테일, 또는 단독으로 즐기며, 풍부한 풍미와 달콤함 덕분에 다양한 음료에 활용됩니다. 예를 들어, 그랑 마르니에(Grand Marnier)와 같은 오렌지 리큐어나, 카카오와 커피 맛을 지닌 리큐어가 그 예입니다.",
    url: "/"
  },{
    key:"OTHER",
    title:"기타증류주(진,럼,보드카,데킬라)",
    description:"진, 럼, 보드카, 데킬라의 경우 위스키는 아니지만, 진은 주로 보리, 밀, 옥수수 등의 곡물을 발효시킨 증류주이며 디양한 허브, 향신료를 추가하여 맛을 낸 증류주입니다. 이외에도 다른 종류의 특별한 증류주들을 즐겨보세요",
    url: "/"
  },{
    key:"COCKTAIL",
    title:"칵테일",
    url: "/"
  }
]

function MenuPage(props) {

  const [isProductLoading, setIsProductLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [favoriteProducts, setFavoriteProducts] = useState([]);

  const getProductSuccess = (response) => {

    if(response.status === 200){
      setProducts(response.data.products);
    }
    setIsProductLoading(false);
  };

  const getProductError = (error) => {
    setIsProductLoading(false);
  };

  const getFavoriteProductSuccess = (response) => {

    if(response.status === 200){
      setFavoriteProducts((prev) => response.data.product);
    }
    setIsProductLoading(false);
  };

  const getFavoriteProductError = (error) => {
    setIsProductLoading(false);
  };

  useEffect(() => {
    if(isProductLoading){
      ProductController.getFavoriteProducts({number : 4}, getFavoriteProductSuccess, getFavoriteProductError);
      ProductController.getProducts({}, getProductSuccess, getProductError);
    }
  }, [isProductLoading]);

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative', boxSizing:'border-box', overflow:'scroll' }}>
      <Box sx={{width:'100%', position:'relative', minHeight:{xs:'60vh',md:'40vh'}, padding:'72px 0px'}}>
        <img src="/images/menu_image.jpg"
          style={{position:'absolute', width:'100%', height:'100%', top:'0px', left:'0px', right:'0px', bottom:'0px', objectFit:'cover'}}/>
        <Box sx={{
          position:'absolute',
          width:'100%',
          height:'100%',
          top:'0px', left:'0px', right:'0px', bottom:'0px',
          background:'linear-gradient(180deg, rgba(0,0,0,.3), #ffffff)'}}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{width:'100%', height:'100%', position:'relative', padding:'0px 24px', boxSizing:'border-box'}}
            spacing={2}>
            <Typography variant="h6" element="p" sx={{
              fontFamily: 'Gowun Bold',
              textAlign:"center", fontSize:'32px', fontWeight:900, color:'#ffffff'}}>
              좋아하는 위스키를 검색해보세요
            </Typography>
            <Box sx={{ width:'100%', maxWidth:'720px'}}>
              <StyledTextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{color:'#ffffff'}}/>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width:'100%',
                  borderColor:'#ffffff',
                  borderRadius:'24px',
                  color:"#ffffff"
                }}
                fullWidth
                label="검색어"
                id="fullWidth" />
            </Box>
            <Box sx={{ width:'100%', maxWidth:'720px'}}>
              {CATEGOTY_LIST.map((el) => (
                <Button
                  key={el.key}
                  variant="outlined"
                  sx={{
                    fontFamily: 'Gowun Regular',
                    fontWeight:900, borderRadius:'24px', m:"4px", backgroundColor:'#ffffff', color:'#222', border:'0px'}}>{el.title}</Button>
              ))}
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Box sx={{position:'relative', width:'100%'}}>
          <Typography variant="h6" element="p" sx={{
            fontFamily: 'Gowun Bold',
            textAlign:"center", fontSize:'32px', fontWeight:900, color:'#222222'}}>
            Today's PICK
          </Typography>
        </Box>
      </Box>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Box sx={{position:'relative', width:'100%'}}>
          <Grid
            container
            spacing={2}
            sx={{width:'100%'}}>
            {favoriteProducts && favoriteProducts.map((el) => (
              <MenuItem
                item={el}/>
            ))}
          </Grid>
        </Box>
      </Box>
      {CATEGOTY_LIST.map(el => (
        <>
        <Box sx={{position:'relative', width:'100%', height:'560px', boxSizing:'border-box'}}>
          <img src="/images/scotch_whiskey.jpg" style={{
            position:'absolute',
            top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
            objectFit:'cover', filter:'brightness(50%)'
          }}/>
          <Stack
            sx={{
              position:'absolute',
              boxSizing:'border-box',
              top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
              padding:'36px'
            }}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            <Typography element="p" sx={{
              fontFamily: 'Gowun Bold',
              textAlign:"center", fontWeight:900,mb:'0px', fontSize:'32px', color:'#ffffff'}}>
              {el.title}
            </Typography>
            <Typography element="p" sx={{
              fontFamily: 'Gowun Bold',
              textAlign:"center", mb:'24px', fontSize:'18px', color:'#bbbbbb'}}>
              {el.description}
            </Typography>
          </Stack>
        </Box>
        <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
          <Box sx={{position:'relative', width:'100%'}}>
            <Grid
              container
              spacing={2}
              sx={{width:'100%'}}>
              {
                products.filter(_el => _el.description.type === el.key ).map(_el => (
                  <MenuItem
                    item={_el}/>
                ))
              }
            </Grid>
          </Box>
        </Box>
        </>
      ))}
      <Footer/>
    </Box>
  );
}

MenuPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default MenuPage;
