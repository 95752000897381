import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';

import ShoppingCartCheckoutTwoToneIcon from '@mui/icons-material/ShoppingCartCheckoutTwoTone';
import LocalMallTwoToneIcon from '@mui/icons-material/LocalMallTwoTone';

import ApprovalTwoToneIcon from '@mui/icons-material/ApprovalTwoTone';

import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';

import LocalFireDepartmentTwoToneIcon from '@mui/icons-material/LocalFireDepartmentTwoTone';
import WhatshotTwoToneIcon from '@mui/icons-material/WhatshotTwoTone';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import BalanceTwoToneIcon from '@mui/icons-material/BalanceTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import MarkEmailReadTwoToneIcon from '@mui/icons-material/MarkEmailReadTwoTone';
import PolylineTwoToneIcon from '@mui/icons-material/PolylineTwoTone';
import WarehouseTwoToneIcon from '@mui/icons-material/WarehouseTwoTone';

import CrisisAlertTwoToneIcon from '@mui/icons-material/CrisisAlertTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import BuildTwoToneIcon from '@mui/icons-material/BuildTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import FitbitTwoToneIcon from '@mui/icons-material/FitbitTwoTone';

import StackedBarChartTwoToneIcon from '@mui/icons-material/StackedBarChartTwoTone';
import IntegrationInstructionsTwoToneIcon from '@mui/icons-material/IntegrationInstructionsTwoTone';
import IosShareTwoToneIcon from '@mui/icons-material/IosShareTwoTone';

import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import LiquorTwoToneIcon from '@mui/icons-material/LiquorTwoTone';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import CircleNotificationsTwoToneIcon from '@mui/icons-material/CircleNotificationsTwoTone';
import MeetingRoomTwoToneIcon from '@mui/icons-material/MeetingRoomTwoTone';
//import LocalMallTwoToneIcon from '@mui/icons-material/LocalMallTwoTone';

import MiscellaneousServicesTwoToneIcon from '@mui/icons-material/MiscellaneousServicesTwoTone';

const MainMenuItems = [
  {
    key : 'about',
    title : 'About',
    type : 'link',
    to : '/about',
    icon : <StorefrontTwoToneIcon />
  },
  {
    key : 'menu',
    title : '메뉴',
    type : 'link',
    to : '/menu',
    icon : <MenuBookTwoToneIcon />
  },
  {
    key : 'event',
    title : '이벤트',
    type : 'link',
    to : '/events',
    icon : <EventTwoToneIcon />,
  },
  {
    key : 'notice',
    title : '공지사항',
    type : 'link',
    to : '/notices',
    icon : <CircleNotificationsTwoToneIcon />,
  },
  {
    key : 'reservation',
    title : '예약관리',
    type : 'link',
    to : '/reservation',
    icon : <MeetingRoomTwoToneIcon />,
  }
];

export default MainMenuItems;
