import React, {useRef, useEffect, useState} from 'react';

import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { pink } from '@mui/material/colors';

import { SnackbarProvider, useSnackbar } from 'notistack';

import css_main from '../../css/main.module.css';

function SignedLayoutInner(props) {
  const { window } = props;
  const navigate = useNavigate();

  const [appBarHeight, setAppBarHeight] = useState(0);

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', height:"100vh" }}>
      <Box component="main" sx={{width: "100%", overflow:"hidden" }}>
        <Box component="div" sx={{position:'relative', width:"100%", p:0, height:`calc(100vh - ${appBarHeight}px)`}}>
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}

SignedLayoutInner.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function NonNothingLayout(props) {
  return (
    <SignedLayoutInner {...props}>
      {props.children}
    </SignedLayoutInner>
  );
}
