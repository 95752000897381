import * as React from 'react';
import PropTypes from 'prop-types';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import WineBarTwoToneIcon from '@mui/icons-material/WineBarTwoTone';
import LiquorIcon from '@mui/icons-material/Liquor';
import TagIcon from '@mui/icons-material/Tag';

import {
  Grid,
  Button,
  Container,
  Stack,
  Chip,
  Box,
  Typography
} from '@mui/material';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 120
  }
};

function RecommendCarousel(props){
  return (
    <Carousel
      style={{width:'100%', height:'100%', position:'relative'}}
      swipeable={true}
      draggable={true}
      showDots={false}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={false}
      autoPlay={false}
      keyBoardControl={true}
      transitionDuration={300}
      partialVisible={true}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      deviceType={props.deviceType}
      dotListClass="custom-dot-list-style">
      <Box sx={{width:'220px', position:'relative', padding:'0px 20px 0px 0px'}}>
        <Box sx={{position:'relative', width:'100%', paddingBottom:'100%', mb:'12px'}}>
          <img src="/images/whiskey2.jpg" style={{
            position:'absolute',
            top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
            borderRadius:'16px', objectFit:'cover'
          }}/>
        </Box>
        <Typography element="p" sx={{textAlign:"left", fontWeight:900, mb:'12px', fontSize:'20px'}}>
          맥켈란 12 셰리/더블
        </Typography>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}>
            <WineBarTwoToneIcon
              fontSize="small"/>
            <Typography element="p" sx={{textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
              14,000
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{marginBottom:'12px'}}
            spacing={2}>
            <LiquorIcon
              fontSize="small"/>
            <Typography element="p" sx={{textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
              180,000
            </Typography>
          </Stack>
          <Typography
            sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                textAlign:'left',
                lineHeight:2,
                WebkitLineClamp: 2,
            }}>
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="스카치 위스키" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="과실향" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="스카치 위스키" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="과실향" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="스카치 위스키" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="과실향" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="스카치 위스키" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="과실향" />
          </Typography>
        </Stack>
      </Box>
      <Box sx={{width:'220px', position:'relative', padding:'0px 20px 0px 0px'}}>
        <Box sx={{position:'relative', width:'100%', paddingBottom:'100%', mb:'12px'}}>
          <img src="/images/whiskey2.jpg" style={{
            position:'absolute',
            top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
            borderRadius:'16px', objectFit:'cover'
          }}/>
        </Box>
        <Typography element="p" sx={{textAlign:"left", fontWeight:900, mb:'12px', fontSize:'20px'}}>
          맥켈란 12 셰리/더블
        </Typography>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}>
            <WineBarTwoToneIcon
              fontSize="small"/>
            <Typography element="p" sx={{textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
              14,000
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{marginBottom:'12px'}}
            spacing={2}>
            <LiquorIcon
              fontSize="small"/>
            <Typography element="p" sx={{textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
              180,000
            </Typography>
          </Stack>
          <Typography
            sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                textAlign:'left',
                lineHeight:2,
                WebkitLineClamp: 2,
            }}>
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="스카치 위스키" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="과실향" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="스카치 위스키" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="과실향" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="스카치 위스키" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="과실향" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="스카치 위스키" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="과실향" />
          </Typography>
        </Stack>
      </Box>
      <Box sx={{width:'220px', position:'relative', padding:'0px 20px 0px 0px'}}>
        <Box sx={{position:'relative', width:'100%', paddingBottom:'100%', mb:'12px'}}>
          <img src="/images/whiskey2.jpg" style={{
            position:'absolute',
            top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
            borderRadius:'16px', objectFit:'cover'
          }}/>
        </Box>
        <Typography element="p" sx={{textAlign:"left", fontWeight:900, mb:'12px', fontSize:'20px'}}>
          맥켈란 12 셰리/더블
        </Typography>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}>
            <WineBarTwoToneIcon
              fontSize="small"/>
            <Typography element="p" sx={{textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
              14,000
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{marginBottom:'12px'}}
            spacing={2}>
            <LiquorIcon
              fontSize="small"/>
            <Typography element="p" sx={{textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
              180,000
            </Typography>
          </Stack>
          <Typography
            sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                textAlign:'left',
                lineHeight:2,
                WebkitLineClamp: 2,
            }}>
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="스카치 위스키" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="과실향" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="스카치 위스키" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="과실향" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="스카치 위스키" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="과실향" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="스카치 위스키" />
            <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label="과실향" />
          </Typography>
        </Stack>
      </Box>
    </Carousel>
  );
}

export default RecommendCarousel;
