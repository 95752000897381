import React from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Chip,
  Container,
  Typography,
  Stack,
  Divider
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import moment from 'moment';
import 'moment/locale/ko';

function NoticeContentPage(props){

  const navigate = useNavigate();

  const noticeBackAction = () => {
    navigate(-1);
  };

  return (
    <>
      <Box sx={{position:'relative', width:'100%'}}>
        <Container
          sx={{position:'relative', width:'100%', py:'18px'}}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "flex-start",
              alignItems: "center",
              position: 'relative',
              width: '100%'
            }}>
            <IconButton
              onClick={noticeBackAction}>
              <ArrowBackIcon />
            </IconButton>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:900,
                textAlign:'center',
                fontSize:'20px',
                color:blueGrey[800]
              }}>
              첫 게시물 테스트
            </Typography>
          </Stack>
        </Container>
      </Box>
      <Container>
        <Box sx={{ my: 2, padding:'24px 0px 108px' }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width:'100%'}}
            alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'left',
                  fontSize:'32px',
                  mb:'6px',
                  color:blueGrey[800]
                }}>
                첫 게시물 테스트
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:400,
                  textAlign:'left',
                  fontSize:'16px',
                  mb:'18px',
                  color:blueGrey[800]
                }}>
                2024. 09. 02.
              </Typography>
              <Divider />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 2, padding:'24px 0px 108px' }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width:'100%'}}
            alignItems="center">
            <Grid item xs={12} sm={12} md={12}>

            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default NoticeContentPage;
