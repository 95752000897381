import React, {useEffect, useState, useRef} from 'react';

export default function RedirectPage(props){

  const { loc } = props;

  useEffect(() => {
    window.location = loc;
  },[]);

  return (
    <section>Redirecting...</section>
  );
}
