import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

import AliceReviewList from '../../components/dashboard/AliceReviewList';
import DOReviewController from '../../apis/dailyorder/DOReviewController';
// color
import { teal } from '@mui/material/colors';

import axios from "axios";

function DashboardPage(props){

  const handlePreviousVersion = () => {
      props.__versionChange("V1");
  }

  const [files, setFiles] = useState([]);

  const getFileListSuccess  = (response) => {
    console.log(response);
  };

  const getFileListError  = (error) => {
    console.log(error);
  };

  useEffect(() => {

  }, []);

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative',  boxSizing:'border-box' }}>
      {/*<Box sx={{width:'100%', position:'fixed', top:'0px', left:'0px'}}>
        <Toolbar />
        <Box sx={{width:'100%', backgroundColor:teal[700], p:'6px 32px'}}>
          <Typography variant="h6" componant="p" sx={{textAlign:"left", color:'#ffffff', fontSize:'14px'}}>
            <Typography
              onClick={handlePreviousVersion}
              variant="p"
              componant="span"
              sx={{textAlign:"left", color:'#ffffff', fontSize:'14px', mr:'12px', cursor:'pointer'}}>
              기존 버전 으로 돌아가기
            </Typography>
            ( 안정화 전까지 기존 버전이 운영됩니다 )
          </Typography>
        </Box>
      </Box>*/}
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Typography variant="h6" element="p" sx={{textAlign:"left", mb:'24px'}}>

        </Typography>
        <Box sx={{position:'relative', width:'100%'}}>

        </Box>
      </Box>
    </Box>
  );
}

export default DashboardPage;
