import React from 'react';

import {
  Box,
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Chip,
  Container,
  Typography,
  Stack
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import NoticeTable from '../../components/notice/NoticeTable';

import moment from 'moment';
import 'moment/locale/ko';

function NoticePage(props){
  return (
    <>
      <Container>
        <Box sx={{ my: 2, padding:'48px 24px 108px' }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width:'100%'}}
            alignItems="center">
            <Grid item md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'left',
                  fontSize:'32px',
                  mb:'48px',
                  color:blueGrey[800]
                }}>
                공지사항
              </Typography>
            </Grid>
            <Grid item md={12}>
              <NoticeTable />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default NoticePage;
