import React from 'react';
import { Routes, Route, Switch, Navigate, useNavigate, useLocation } from 'react-router-dom';
import useToken from '../utils/useToken'
import axios from 'axios';

import {
  DashboardPage,
  MenuAdminPage,
  MenuAdminUpdatePage,

  QueueLayout,
  SignedLayout,
  SignedSubLayout,

} from '../containers'

export default function SignedRoute(props){

  const { token, removeToken, setToken } = useToken();
  const navigate = useNavigate();
  const { state } = useLocation();
  // errorComposer will compose a handleGlobally function
  const errorComposer = (error) => {

      return () => {
          const statusCode = error.response ? error.response.status : null;
          if (statusCode === 404) {

          }

          if (statusCode === 401) {
            props.onRemoveToken();
          }
      }
  }

  axios.interceptors.response.use(undefined, function (error) {
      error.handleGlobally = errorComposer(error);

      return Promise.reject(error);
  })

  if (state) {
      navigate(state);
  }

  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route path='/dashboard' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DashboardPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/admin/menu' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <MenuAdminPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/admin/product/:id' element={
        <QueueLayout>
          <SignedSubLayout title="정보 수정하기" removeToken={removeToken}>
            <MenuAdminUpdatePage {...props} token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/admin/events' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DashboardPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/admin/notices' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DashboardPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/admin/reservation' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DashboardPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
    </Routes>
  )
}
